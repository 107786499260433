import React from 'react'

// rSuite
import { Button } from 'rsuite'
import Icon from './Icon'

// redux
import { useSelector, useDispatch } from 'react-redux'
import { getOptions, getContextOptions } from '../redux/options/selectors' 
import { setOption } from '../redux/options/actions' 


const ResetFiltersButton = ( { context = 'filters' } ) => {

  const { car = '', group = '', penalty = '' } = getContextOptions( useSelector( getOptions ), context )
  const dispatch = useDispatch()
  const onClick = () => {
    dispatch( setOption( 'car', '', context ) ) 
    dispatch( setOption( 'group', '', context ) ) 
    dispatch( setOption( 'penalty', '', context ) ) 
  }

  return (
    <Button
      onClick={ onClick } 
      className="reset"
      disabled={ !car && !group && isNaN( parseInt( penalty ) ) }
    ><Icon icon="refresh"/> <span className="label">Reset</span></Button>
  )
}

export default ResetFiltersButton