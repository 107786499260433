// convert laptime string to miliseconds
export const laptime2ms = time =>
{
  // split the string
  let [ a, ms ] = time.split( '.' ),
      [ m, s ] = a.split( ':' )
  
  // make sure all values are integers & return the combined result
  return parseInt( '0' + ms ) + parseInt( '0' + s ) * 1000 + parseInt( '0' + m ) * 60 * 1000
}

// convert miliseconds to laptime string
export const ms2laptime = time =>
{
  const m = String( '0' + parseInt( time / 60000 ) ).substr( -1 ),
        s = String( '00' + parseInt( time / 1000 ) % 60).substr( -2 ),
        ms = String( '000' + time ).substr( -3 )
  return m + ':' + s + '.' + ms
}

// convert miliseconds to time difference in seconds.miliseconds
export const ms2diff = time =>
{
  // return empty when zero or invalid
  if ( !time ) return ''
  // get abs time, seconds and milliseconds
  const a = Math.abs( time ),
        s = parseInt( a / 1000 ) % 60,
        ms = String( '000' + a ).substr( -3 )
  // return the string
  return ( time > 0 ? '+' : '-' ) + s + '.' + ms
}