import { createSelector } from 'reselect'
import { getUnique, numSort } from '../../tools/Array'
import { getGroupFilter } from '../options/selectors'

// get data from state
const getAllData = ( { data } ) => Array.isArray( data ) ? data : []

// get data
export const getData = createSelector(
  [ getAllData ],
  ( data ) => data
)

// get unique groups
export const getGroups = createSelector(
  [ getAllData ],
  ( data ) => getUnique( data, 'cat' )
)

// get unique car ids
export const getAllCars = createSelector(
  [ getAllData ],
  ( data ) => getUnique( data, 'car' )
)

// get unique car ids, take group into account
export const getGroupCars = createSelector(
  [ getAllData, getGroupFilter ],
  ( data, group ) => getUnique( group ? data.filter( d => d.cat === group ) : data, 'car' )
)

// get unique car ids with groups
export const getCarsAndGroups = createSelector(
  [ getAllData, getAllCars ],
  ( data, cars ) => {
    const out = {}
    for( const car of cars )
    {
      const dcar = data.find( d => d.car === car )
      if ( dcar ) out[ car ] = dcar.cat      
    }
    return out
  }
)

// get unique penalties
export const getPenalties = createSelector(
  [ getAllData ],
  ( data ) => getUnique( data, 'penalty', numSort )
)