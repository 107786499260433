import React, { useEffect } from 'react'

// components
import FilterDropdown from './FilterDropdown'
import { Dropdown } from 'rsuite'

// redux
import { useSelector, useDispatch } from 'react-redux'
import { getOptions, getContextOptions } from '../redux/options/selectors' 
import { setOption } from '../redux/options/actions' 
import { getPenalties } from '../redux/data/selectors'
import { useLocation } from 'react-router'
import { useMatomo } from '@datapunt/matomo-tracker-react'

const PenaltyDropdown = ( { context = 'filters' } ) =>
{
  const dispatch = useDispatch()
  // get all options
  const options = useSelector( getOptions )     
  // try to get filters from context
  const { penalty = ''  } = getContextOptions( options, context )
    // other selectors
  const penalties = useSelector( getPenalties )
  const onSelect = value => dispatch( setOption( 'penalty', value, context ) )

  const location = useLocation()
  const{ trackEvent } = useMatomo()

  useEffect( () => {
    
    // track prenalty filter event
    trackEvent( { 
      category: 'filter',
      action: 'penalty',
      name: penalty,
      href: location.pathname
    } )
  }, [ penalty ] )

  return (
    <FilterDropdown 
      className="filter-penalties"
      title={ penalty !== null && penalty !== '' ? `+${penalty}s` : 'Penalty' }
      selectAll = 'All penalties'
      icon="exclamation-triangle"
      onSelect={ onSelect }
      selected={ penalty }
    >
    { penalties.map( i => <Dropdown.Item eventKey={ i } key={ `key-${i}` }>{ `+${i}s` }</Dropdown.Item> ) }
    </FilterDropdown>
  )
}

export default PenaltyDropdown