import React from 'react'
import { Drawer, Form, FormGroup, ControlLabel } from 'rsuite'
import Icon from './Icon'

// components
import YouForm from './YouForm'
import NotifyOptions from './NotifyOptions'
import DisplayOptions from './DisplayOptions'


const OptionsDrawer = ( { show = false, onClose } ) =>
{ 
  // fix double intro animation
  const onEntered = ( e ) => {
    e.classList.remove( 'slide-in', 'animated' )
  }

  return (
    <Drawer show={ show } onHide={ onClose } className="options-drawer" onEntered={ onEntered }>
      <Drawer.Header>
        <Drawer.Title><Icon icon="avatar"/> Your preferences</Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        <Form>
          <FormGroup className="you">
            <ControlLabel><Icon icon="car"/> Registration number</ControlLabel>
            <YouForm onClose={ onClose }/>
          </FormGroup>
          <FormGroup className="display">
            <ControlLabel><Icon icon="eye"/> Display charts &amp; graphs</ControlLabel>
            <DisplayOptions/>           
          </FormGroup>
          <FormGroup className="notifications">
            <ControlLabel><Icon icon="bell"/> In-app notifications</ControlLabel>
            <NotifyOptions/>            
          </FormGroup>
          <FormGroup className="credits">
            <Icon icon="rebel"/>App by <a href="mailto:sanderfalise@gmail.com?subject=Wimedo app" target="_blank" rel="noreferrer">Sander Falise</a> &copy; 2021
          </FormGroup>
        </Form>        
      </Drawer.Body>
    </Drawer>
  )
}

export default OptionsDrawer