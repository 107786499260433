import { combineReducers } from 'redux'
import optionsReducer from './options/reducer'
import dataReducer from './data/reducer'
import notificationsReducer from './notifications/reducer'

const rootReducer = combineReducers({
  options: optionsReducer,
  data: dataReducer,
  notifications: notificationsReducer
});

export default rootReducer;