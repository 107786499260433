import React, { useState, useEffect } from 'react'
import { Navbar, Nav, Button, Modal } from 'rsuite'
import { Switch, Route, Link } from 'react-router-dom'
import Icon from './components/Icon'
import axios from 'axios'

// assets
import Logo from './assets/wimedo-icon.png'

// redux
import { useSelector, useDispatch } from 'react-redux'
import { getYou, getAskedYou } from './redux/options/selectors' 
import { setOption } from './redux/options/actions' 
import { setData } from './redux/data/actions' 
import groupTypes from './redux/options/groups'

// styles
//import 'rsuite/lib/styles/index.less'
import 'rsuite/lib/Navbar/styles'
import 'rsuite/lib/Nav/styles' 
//import 'rsuite/lib/Button/styles'
// import 'rsuite/lib/Icon/styles'
// import 'rsuite/lib/ButtonGroup/styles'
import 'rsuite/lib/Dropdown/styles'
import 'rsuite/lib/SelectPicker/styles'
import 'rsuite/lib/Toggle/styles'
import 'rsuite/lib/Notification/styles'
//import 'rsuite/lib/Form/styles'
//import 'rsuite/lib/FormGroup/styles'
import 'rsuite/lib/Divider/styles'
import 'rsuite/lib/Panel/styles'
import 'rsuite/lib/Pagination/styles'
import 'rsuite/lib/Container/styles'
//import 'rsuite/lib/Header/styles'
import 'rsuite/lib/Content/styles'
import 'rsuite/lib/Drawer/styles'
import 'rsuite/lib/Input/styles'
import 'rsuite/lib/InputGroup/styles'
import 'rsuite/lib/Modal/styles'
import 'rsuite/lib/Modal'
import './css/App.less' 

// pages
import HomePage from './pages/HomePage'
import GroupPage from './pages/GroupPage'
import CarPage from './pages/CarPage'

// components
import NavLink from './components/NavLink'
import OptionsDrawer from './components/OptionsDrawer'
import { Notifications } from './components/Notifications'
import { getData } from './redux/data/selectors'
import { useMatomo } from '@datapunt/matomo-tracker-react'
import { apiUrl, pollDelayShort, pollDelayLong } from './config'

// allow some url params to modify the app behaviour
const urlParams = new URLSearchParams( window.location.search )
const apiParams = new URLSearchParams()

let timeoutId = 0
// last update value
let lastUpdate = ''

// load data from server
const requestData = () => {
  // check for master param
  if ( urlParams.has( 'i' ) &&  urlParams.get( 'i' ) === 'sander' )
  {
    // resets fake data
    if ( urlParams.has( 'reset' ) )
    {
      apiParams.set( 'reset', 1 )
      lastUpdate = ''
    }

    if ( urlParams.has( 'fake' ) ) 
    {
      // add fake data
      apiParams.set( 'fake', urlParams.get( 'fake' ) )      
    }
    else if ( urlParams.has( 'date' ) ) 
    {
      // get specific date
      apiParams.set( 'date', urlParams.get( 'date' ) )
    }  
  }

  // add last update param
  if ( lastUpdate )
  {    
    apiParams.set( 'since', lastUpdate )
  }

  // return the axios result
  return axios.get( apiUrl + '?' + apiParams.toString() )
}

const App = () => {

  // state
  const [ showOptions, setShowOptions ] = useState( false )

  const { enableLinkTracking } = useMatomo()
  enableLinkTracking()

  // redux
  const you = useSelector( getYou )
  const askedYou = useSelector( getAskedYou )
  const data = useSelector( getData )
  const dispatch = useDispatch()

  // reset last update when there is no data
  if ( !data )
  {
    lastUpdate = ''
  }
  
  // enqueue data for the next run
  const queueData = ( delay = 0 ) => {
    if ( !delay ) delay = pollDelayShort
    // reset interval
    if ( timeoutId ) clearTimeout( timeoutId );
    // plan the next data retrieval
    timeoutId = setTimeout( () => { loadData() }, delay )
  }

  // get last updated entry
  const getLastUpdate = ( rows ) => 
  {
    for( const row of rows )
    {       
      // update lastUpdate time
      if ( row.hasOwnProperty( 'time' ) && row.time > lastUpdate )
      {
        lastUpdate = row.time
      } 
    }
  }
  
  // get and set data in state
  const loadData = () => { 
    let delay = pollDelayShort
    requestData()
      // set data on success
      .then( response => {
        // make sure we have a response and some data
        if ( response && response.data )
        {
          // we're expecting an array to be returned
          if ( Array.isArray( response.data.data ) )
          {
            // no need to update if we hava zero length array
            if ( response.data.data.length )
            {
              // get last update time
              getLastUpdate( response.data.data )

              // update data
              dispatch( setData( response.data.data ) )
            }
          }
          else
          {
            // no data at all? reset the last update time and try to load all data next time
            lastUpdate = ''
          }

          // slower polling when getting from cache
          if ( response.data.hasOwnProperty( 'method' ) && response.data.method.indexOf( 'cache' ) >= 0 )
          {
            delay = pollDelayLong
          }          
        }
        else
        {
          // no response at all? reset the last update time and try to load all data next time
          lastUpdate = ''
        }
      } )
      // ignore errors
      .catch( error => console.log( error ) )
      // always queue next request
      .then( () => queueData( delay ) )
  }

  // load data on init
  useEffect( () => {
    // load data for the first time
    loadData()

    // reset fastest times
    dispatch( setOption( groupTypes.FAST, {} ) )

    // show you form ONCE
    if ( !you && !askedYou)
    {
      setShowOptions( true )
      dispatch( setOption( 'askedYou', true ) )
    }
  }, [] )

  // show / hide options panel
  const onShowOptions = () => setShowOptions( true )
  const onHideOptions = () => setShowOptions( false )

  return (
      <div className="WimedoApp">
        
        <Navbar className="navbar" appearance="inverse">
          <Navbar.Header>
            <Link to="/" className="logo">
              <img src={ Logo } alt="Wimedo logo"/>
            </Link>
          </Navbar.Header>
          <Navbar.Body> 
            <Nav>
              <NavLink icon="home" to="/">Home</NavLink>
              <NavLink icon="hashtag" to="/group">Group</NavLink>
              <NavLink icon="car" to="/car">Car</NavLink>
            </Nav>
          </Navbar.Body>         

          <Button
            onClick={ onShowOptions } 
            className="you"
          ><Icon icon="avatar" className={ you ? 'active' : '' } /> { you ? you : '000' }</Button>

        </Navbar>

        <div className="main-content">

          <Modal open={ true }>Hello</Modal>
          <Switch>
            <Route exact={ true } path="/">
              <HomePage/>
            </Route>
            <Route path="/group/:group?">
              <GroupPage/>
            </Route>
            <Route path="/car/:car?">
              <CarPage/>
            </Route>
          </Switch>  
        </div>   
        
        <Notifications/>

        <OptionsDrawer show={ showOptions } onClose={ onHideOptions } />

      </div>
  )
}

export default App
