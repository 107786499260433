import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'

// add redux
import { Provider } from 'react-redux'
import { store, persistor } from './redux/store'
import { PersistGate } from 'redux-persist/integration/react'

import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react'

import { matomoUrl } from './config'

const matomo = createInstance({
  urlBase: matomoUrl,
  siteId: 44,
  trackerUrl: matomoUrl + 'piwik.php',
  srcUrl: matomoUrl + 'piwik.js',
  linkTracking: false
})

ReactDOM.render(
  <React.StrictMode>
    { /* Matomo / piwik provider */ }
    <MatomoProvider value={ matomo }>

      { /* Redux provider */ }
      <Provider store={ store }>

        { /* Wait for store to be persisted */ }
        <PersistGate loading={ null } persistor={ persistor }>

          { /* Root browserRouter */ }
          <BrowserRouter>

            { /* The actual app */ }
            <App />

          </BrowserRouter>

        </PersistGate>

      </Provider>

    </MatomoProvider>
    
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
