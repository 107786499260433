import React, { useEffect } from 'react'

// redux
import { useSelector, useDispatch } from 'react-redux'
import { getYou, getFastest, getNotificationOptions } from '../redux/options/selectors' 
import { getData } from '../redux/data/selectors' 
import { setOption } from '../redux/options/actions' 
import groupTypes from '../redux/options/groups'

// assets
import { Notification } from 'rsuite'
import Icon from './Icon'
import { laptime2ms, ms2laptime } from '../tools/Time'
import { notificationTime, notificationsMax } from '../config'

// is this row regarding you?
const isYou = ( car, you ) => String( car ) === String( you )

/**
 * Show a React and Web notification
 * @param {*} props 
 */
const showNotification = ( props ) =>
{
  const {
    you = 0,
    data = null,
    context = '',
    type = 'info',
    title = '',
    duration = notificationTime
  } = props

  let alert_body = []

  if ( data )
  {
    if ( typeof data === 'string' )
    {
      alert_body.push( <p>{data}</p> )
    }
    // previous data
    else if ( data.prev )
    {
      const prev_laptime = laptime2ms( data.prev.laptime )

      if ( data.next )
      {
        const next_laptime = laptime2ms( data.next.laptime )
        const diff_laptime = prev_laptime - next_laptime
        const diff = ms2laptime( Math.abs( diff_laptime ) )
        const className = diff_laptime > 0 ? 'neg' : 'pos'
        const prefix =  diff_laptime > 0 ? '-' : '+'
        
        alert_body.push( 
          <React.Fragment>          
            <p className="laptime-new">{ data.next.laptime }</p>
            <p className="wrap next">                            
              <div className="car">
               { isYou( data.next.car, you ) ? <Icon icon="avatar"/> : <Icon icon="car"/> } { data.next.car }                 
              </div>                            
              <div className={ `timediff ${ className }`}>{ prefix } { diff }</div>
            </p> 
          </React.Fragment>
        )
      }
    
      alert_body.push( 
        <p className="prev">
          <span className="label">Before</span>
          <span className="car">
            { isYou( data.prev.car, you ) ? <Icon icon="avatar"/> : <Icon icon="car"/> } { data.prev.car }                                      
          </span>
          <span className="laptime-old"><Icon icon="clock-o"/> { data.prev.laptime }</span>                           
        </p> 
      )
    }
    else if ( data.next )
    {
      alert_body.push( 
        <React.Fragment>
          <p className="laptime laptime-new">{ data.next.laptime }</p>
          <p className="car">
           { isYou( data.next.car, you ) ? <Icon icon="avatar"/> : <Icon icon="car"/> } { data.next.car }
          </p>
        </React.Fragment>
      )
    }
  }

  let className =  `type-${type} context-${context}`
  if ( data.next && you === data.next.car ) className += ' you'

  Notification.open({
    title: title,
    className: className,
    style: { '--duration': duration / 1000 + 's' },
    description: alert_body,
    duration: duration
  })
}

/**
 * Check all rows to see if we need to show any notifications
 * @param {*} param0 
 */
const checkRowNotifications = ( { rows, fastest, notify, you, update = null } ) =>
{
  // to be filled later
  let faster_day = null,
      faster_group = null,
      faster_you = null,   
      new_you = null,
      updated = false,
      // object with all fast times to compare with
      faster = { ...fastest },
      // list of cars with notifications, prevents multiple notifications per car
      cars = []

  // guess the group from the ID
  const group = you ? String( parseInt( you / 100 ) ) : ''

  // notifications to be shown
  const notifications = []

  // stop here if there are no rows
  if ( !rows || !Array.isArray( rows ) || !rows.length ) return

  // go over each row
  rows.forEach( row => 
  { 
    // get stuff from row
    const {
      car = '', 
      cat = '', 
      laptime = '', 
      lap = 0 
    } = row
    
    // check required fields
    if ( car && cat && laptime )
    {
      // keys for use in the faster object
      const fast_day = 'fast-day',
            fast_group = 'fast-group-' + cat,
            fast_car = 'fast-car-' + car,          
            car_lap = 'car-lap-' + car ,
            is_you = isYou( car, you ),
            is_invalid = row.comment !== '',
            is_penalty = row.penalty > 0
            
      // check fast day laptime
      if ( !faster.hasOwnProperty( fast_day ) || laptime < faster[ fast_day ].laptime )
      {
        // skip invalid laps
        if ( !is_penalty && !is_invalid )
        {
          const prev = fastest.hasOwnProperty( fast_day ) ? faster[ fast_day ] : null 
          const next = { car, laptime }
          faster_day = { prev, next }   
          faster[ fast_day ] = next
          updated = true
        }
      }

      // check fast group laptime
      if ( !faster.hasOwnProperty( fast_group ) || laptime < faster[ fast_group ].laptime )
      {
        // skip invalid laps
        if ( !is_penalty && !is_invalid )
        {
          const prev = faster.hasOwnProperty( fast_group ) ? faster[ fast_group ] : null  
          const next = { car, laptime }    
          if ( group === String ( cat ) )
          {
            faster_group = { prev, next }
          }
          faster[ fast_group ] = next
          updated = true
        }
      }

      // check fast car laptime
      if ( !faster.hasOwnProperty( fast_car ) || laptime < faster[ fast_car ].laptime )
      {
        // skip invalid laps
        if ( !is_penalty && !is_invalid )
        {
          const prev = fastest.hasOwnProperty( fast_car ) ? faster[ fast_car ] : null     
          const next = { car, laptime } 
          if ( is_you )
          {
            faster_you = { prev, next }
          }
          faster[ fast_car ] = next
          updated = true
        }
      }

      // check new lap, allow invalid laps here
      if ( !faster.hasOwnProperty( car_lap ) || lap > faster[ car_lap ] )
      {
        if ( is_you )
        {
          new_you = { prev: null, next: { car: you, laptime } }
        }
        faster[ car_lap ] = lap
        updated = true
      }
    }
  } )

  // show faster day time notification
  if ( faster_day )
  {
    // check notification settings
    if ( ( notify.new_time || notify.day_time ) && isYou( faster_day.next.car, you ) )
    {
        notifications.push( {
          you: you,
          context: 'day',
          type: 'success', 
          data: faster_day,
          title: "You've set fastest day time"
        } )
    }
    else if ( notify.day_time )
    {
      notifications.push( {
        you: you,
        data: faster_day,
        context: 'day',
        // make it red if you previously had the fastest time of the day, otherwise make it orange
        type: faster_day.prev && isYou( faster_day.prev.car, you ) ? 'error' : 'warning',
        title: 'Fastest time of the day'
      } )
    }
  }

  if ( faster_group )
  {
    if ( ( notify.new_time || notify.group_time ) && isYou( faster_group.next.car, you ) )
    {
      notifications.push( {
        you: you,
        context: 'group',
        type: 'success',
        data: faster_group,
        title: "You've set fastest group time"
      } )
    }
    else if ( notify.group_time )
    {
      notifications.push( {
        you: you,
        title: 'Fastest group time',
        data: faster_group,
        context: 'group',
        type: faster_group.prev && isYou( faster_group.prev.car, you ) ? 'error' : 'warning',
      } )
    }
  }

  if ( faster_you && notify.new_time )
  {
    notifications.push( { 
      you: you,
      data: faster_you,
      title: "You've set a faster time",
      context: 'you',
      type: 'success'
    } )
  }

  if ( new_you && notify.new_time )
  {
    notifications.push( { 
      you: you,
      data: new_you,
      title: "You've set a new time",
      context: 'you',
      type: 'warning'
    } )
  }

  // maybe update state
  if ( update && updated )
  {
    update( faster )
  }

  //  show the first 3 notification
  for( let i=0; i<Math.min( notificationsMax, notifications.length ); i++ )
  {
    // shortcut to the notification
    const n = notifications[ i ]
    const car = n.data.next ? n.data.next.car : ''
    // check if this car hasn't yet been notified
    if ( !car || !cars.includes( car ) )
    {
      // store car notification
      if ( car ) cars.push( car )

      // add brand
      // const d = getCarDetails( car )
      // if ( d ) n.brand = d.brand

      // show notification with a short delay
      setTimeout( () => showNotification( n ), ( i + 1 ) * 300 )
    }    
  }   
}

// e,pty component for receiving state
export const Notifications = ( props ) =>
{
  // redux
  const you = useSelector( getYou )
  const fastest = useSelector( getFastest )
  const notify = useSelector( getNotificationOptions )
  const rows = useSelector( getData )

  // dispatcher
  const dispatch = useDispatch()  
  const updateFastest = ( faster ) => dispatch( setOption( groupTypes.FAST, faster ) )

  // check rows when they update
  useEffect( () => {
    checkRowNotifications( { rows, you, fastest, notify, update: updateFastest } )

    // maybe close the last notifications on overflow
    const containers = document.getElementsByClassName( 'rs-notification' )        
    
    if ( containers.length )
    {
      const container = containers[ 0 ]
      
      // use an interval to remove elements until no notifications stick outside of the page
      // this also gives the browser some time to render before we call this check
      let i = setInterval( () =>  
      {
        // make sure we have notifications
        const notifications = container.getElementsByClassName( 'rs-notification-item-close' )

        if ( !notifications.length )
        {
          container.parentNode.removeChild( container );
          clearInterval( i )
          return
        }

        // close the first notification or stop
        if ( container.scrollHeight > container.clientHeight || notifications.length > notificationsMax )
        {          
          notifications[ 0 ].click()
        }
        else
        {
          clearInterval( i )
        }
      }, 500 )   
    }
  }, [ rows ] )

  return null
}