import actionTypes from './types'
import groupTypes from './groups'

const INITIAL_STATE = {
  you: '',
  askedYou: false,
  askedyou: false, // fixex a bug where an old type can lead to a white page if the user visited the app when the typo existed
  perPage: 20,
  [ groupTypes.HOMEPAGE ]: {},
  [ groupTypes.GROUPPAGE ]: {},
  [ groupTypes.CARPAGE ]: {},
  [ groupTypes.DISPLAY ]: {
    summary: true,
    time_graph: true,
    position_graph: true,
    day_graph: true
  },
  [ groupTypes.NOTIFY ]: {
    //all: false,   
    //granted: false,
    new_time: true,
    group_time: true,
    day_time: true
  }
}

/**
 * Store an option
 * @param {*} state 
 * @param {*} action 
 * @returns 
 */
const setOption = ( state, action ) =>
{
  // a key is always required
  if ( action.payload.key )
  {
    // if we have a group, update one level deeper
    if ( action.payload.group )
    {
      const value = {
        ...state[ action.payload.group ],
        [ action.payload.key ]: action.payload.value
      }

      return {
        ...state,
        [ action.payload.group ]: value
      }
    }

    // update at the root level
    return {
      ...state,
      [ action.payload.key ]: action.payload.value
    }
  }

  return state
}

/**
 * Reduce options
 * @param {*} state 
 * @param {*} action 
 * @returns 
 */
const optionsReducer = ( state = INITIAL_STATE, action ) =>
{
  switch ( action.type )
  {        
    case actionTypes.SET_OPTION:
      return setOption( state, action )

    default:
      return state;
  }
}

export default optionsReducer