import React from 'react'

// redux
import { useSelector, useDispatch } from 'react-redux'
import { getDisplayOptions } from '../redux/options/selectors' 
import { setOption } from '../redux/options/actions' 

// components
import LabeledToggle from './LabeledToggle'
import { useLocation } from 'react-router'
import { useMatomo } from '@datapunt/matomo-tracker-react'

const DisplayOptions = () => {

  const location = useLocation()
  const{ trackEvent } = useMatomo()

  // get notify options  
  const display = useSelector( getDisplayOptions )

  // dispatcher
  const dispatch = useDispatch()
  const changeOption = ( key, value ) =>
  {
    // track notify option event
    trackEvent( { 
      category: 'options',
      action: 'display',
      name: key,
      value: value,
      href: location.pathname
    } )

    dispatch( setOption( key, value, 'display' ) )
  }

  // change toggle option
  const onToggle = ( key, value ) => 
  {    
    // change option
    changeOption( key, value ) 
  }

  return (
    <div className="display">

      <LabeledToggle 
        onChange={ onToggle } 
        checked={ display.summary } 
        name="summary">Show summary</LabeledToggle>

      <LabeledToggle 
        onChange={ onToggle } 
        checked={ display.day_graph } 
        name="day_graph">Show day times graph</LabeledToggle>

      <LabeledToggle 
        onChange={ onToggle } 
        checked={ display.position_graph } 
        name="position_graph">Show group position graph</LabeledToggle>

      <LabeledToggle 
        onChange={ onToggle } 
        checked={ display.time_graph } 
        name="time_graph">Show car time graph</LabeledToggle>

    </div>

  )
}

export default DisplayOptions