import React from 'react'
import { Nav } from 'rsuite'
import Icon from './Icon'
import { Link, useLocation } from 'react-router-dom'

const NavLink = ( { icon, to, children } ) =>
{
  const location = useLocation()
  return (
    <Nav.Item active={ location.pathname === to } icon={ icon ? <Icon icon={ icon } /> : null } componentClass={ Link } to={ to }>
      <span className="label">{ children }</span>
    </Nav.Item>
  )
}

export default NavLink