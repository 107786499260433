import React, { useEffect } from 'react'

// components
import GroupDropdown from '../components/GroupDropdown'
import FilterTable from '../components/FilterTable'
import { ButtonToolbar, Container, Header, Content } from 'rsuite'

// redux
import { useSelector, useDispatch } from 'react-redux'
import { getOptions, getYou, getContextOptions } from '../redux/options/selectors'  
import { getCarsAndGroups } from '../redux/data/selectors'
import { setOption } from '../redux/options/actions'
import PositionChart from '../components/PositionChart'
import { useParams, useHistory } from 'react-router'
import { useMatomo } from '@datapunt/matomo-tracker-react'

// Group page
const GroupPage = ( { context = 'grouppage' }) => {

  const { trackPageView } = useMatomo()

  // redux
  const dispatch = useDispatch()
  const { group = '', car = '' } = getContextOptions( useSelector( getOptions ), context )
  const cars = useSelector( getCarsAndGroups )
  const you = useSelector( getYou )
  const { group: param } = useParams()
  const history = useHistory()

  // set group on startup if needed
  useEffect( () => 
  {
    trackPageView()

    // reset car if needed
    if ( car )
    {
      dispatch( setOption( 'car', '', context) )
    }

    // change car by url param
    if ( param && param !== group )
    {
      dispatch( setOption( 'group', param, context) )
    }
    // use selected group by default
    else if ( !group )
    {      
      if ( car !== '' && cars.hasOwnProperty( car ) )
      {
        // use selected car as second option
        dispatch( setOption( 'group', cars[ car ], context ) )
      }
      else if ( you !== '' && cars.hasOwnProperty( you )  )
      {
        // use 'your' car as third option
        dispatch( setOption( 'group', cars[ you ], context ) )
      }
      else
      {
        // otherwise use first group
        dispatch( setOption( 'group', cars[ Object.keys( cars )[ 0 ] ], context ) )
      }
    }
  }, [] )

  if ( !param && group )
  {
    history.push( '/group/' + group )
    return null
  }

  // no url param but a you? go to your group
  if ( !param && you && cars.hasOwnProperty( you ) )
  {
    history.push( '/group/' + cars[ you ] )
    return null
  }

  // modify what to show; only positions and no penalties
  const modifyRows = data => data.filter( d => !isNaN( parseInt( d.pos ) ) && !isNaN( parseInt( d.penalty ) ) )

  // hide certain rows
  const hide_rows = [ 'penalty' ]
  const modifyColumns = columns => columns.map( c => (
    {
      ...c,
      show: !c.show ? false : !hide_rows.includes( c.name )
    }
  ) )

  // set column options
  const column_options = {
    id: true,
    lap: false,
    pos: true
  } 

  return (
    <Container className={ context }>
      <Header>
        <ButtonToolbar className="filters">
          <GroupDropdown showAll={ false } context={ context }/>  
        </ButtonToolbar>
      </Header>
      <Content>        
        <FilterTable 
          modifyRows={ modifyRows } 
          modifyColumns={ modifyColumns } 
          context={ context }
          column_options={ column_options }
          between={ <PositionChart/> }
        />
      </Content>
    </Container>
  )
}
export default GroupPage