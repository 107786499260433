import React from 'react'

// redux
import { useSelector, useDispatch } from 'react-redux'
import { getNotificationOptions } from '../redux/options/selectors' 
import { setOption } from '../redux/options/actions' 

// components
import LabeledToggle from './LabeledToggle'
import { useLocation } from 'react-router'
import { useMatomo } from '@datapunt/matomo-tracker-react'

const NotifyOptions = () => {

  const location = useLocation()
  const{ trackEvent } = useMatomo()

  // get notify options  
  const notify = useSelector( getNotificationOptions )

  // dispatcher
  const dispatch = useDispatch()
  const changeOption = ( key, value ) =>
  {
    // track notify option event
    trackEvent( { 
      category: 'options',
      action: 'notify',
      name: key,
      value: value,
      href: location.pathname
    } )

    dispatch( setOption( key, value, 'notify' ) )
  }

  // change toggle option
  const onToggle = ( key, value ) => 
  {    
    // change option
    changeOption( key, value )   
  }

  return (
    <div className="notifications">

      <LabeledToggle 
        onChange={ onToggle } 
        checked={ notify.new_time } 
        name="new_time">Notify me of my new times</LabeledToggle>

      <LabeledToggle 
        onChange={ onToggle } 
        checked={ notify.group_time } 
        name="group_time">Notify me of the fastest group time</LabeledToggle>

      <LabeledToggle 
        onChange={ onToggle } 
        checked={ notify.day_time } 
        name="day_time">Notify me of the fastest day time</LabeledToggle>

    </div>
  )
}

export default NotifyOptions