import React from 'react'
import Icon from './Icon'

// known brands
const brands = [
  'abarth',
  'bmw',
  'citroen',
  'fiat',
  'ford',
  'fury',
  'honda',
  'mazda',
  'mini',
  'nsu',
  'opel',
  'peugeot',
  'porsche',
  'renault',
  'seat',
  'subaru',
  'suzuki',
  'sylva',
  'toyota',
  'vw',
  'westfield'
]

// try to guess the brand from a string
const getBrandFromString = ( str = '' ) =>
{
  // clean up string
  const text = str.toLowerCase().replace( ' ', '' );
  for ( const brand of brands )
  {
    if ( text.indexOf( brand ) === 0 )
    {
      return brand
    }
  }
  return ''
}

// check for existing logo and return logo if so, null if brand isn't known
const CarLogo = ( { brand = '', text = '', ...rest } ) => {

  
  if ( text )
  {
    brand = getBrandFromString( text )
  }
  
  if ( brand && brands.includes( brand ) )
  {
    return <Icon icon={ brand } { ...rest }/>
  }

  return null
}

export default CarLogo