import React from 'react'

// redux
import { useSelector } from 'react-redux'
import { getOptions, getContextOptions } from '../redux/options/selectors' 
import { getGroupCars, getData } from '../redux/data/selectors' 

// components
import SortTable from '../components/SortTable'
import Summary from '../components/Summary'

// set to true to give all entries a position number
const positionAll = false

const FilterTable = ( props ) =>
{
  // get properties
  const { 
    summary = true, 
    before = null,
    between = null,
    after = null,
    context = 'filters', 
    modifyRows = null,
    modifyColumns = null, 
    column_options = null,
    ...rest
  } = props

  // get all options
  const options = useSelector( getOptions )     
  // try to get filters from context
  const { group = '', car = '', penalty = '' } = getContextOptions( options, context )
  // get data and cars
  const data = useSelector( getData )
  // const cars = useSelector( getGroupCars )

  // apply filters to data
  const filterData = ( rows ) => (
    rows.filter( row => (
      ( group === null || group === '' || String( row.cat ) === String( group ) ) &&
      ( car === null || car === '' || String( row.car )=== String( car ) ) &&
      ( penalty === null || penalty === '' || String( row.penalty ) === String( penalty ) )
    ))
  )

  // sort data on laptime ( so we can add a position number later )
  const sortDataOnLaptime = ( rows ) => (
    rows.sort( ( a, b ) => (
      // first to get a fastest laptime wins; so if a car has the same laptime at a later time it goes lower in the ranking
      a.laptime > b.laptime ? 1 : -1 
    ) )
  )

  // add position based on time
  const addPositionAll = ( rows ) => {
    let pos = 1
    return sortDataOnLaptime( rows ).map( ( row, i ) => {
      if ( !row.penalty && !row.comment )
      {
        row.pos = pos++
      }
      return row
    } )
  }

  // add position based on time, but only fastest time per car
  const addPositionOne = ( rows ) => {
    // processed cars
    const processed = []
    // position counter
    let pos = 0
    // go over rows sorted on time
    const sorted = sortDataOnLaptime( rows )    
    // old fashioned for loop so we can break when done
    for ( let i=0; i<sorted.length; i++ )
    {
      // get row
      const row = sorted[ i ]
      // add new or fastest time
      if ( !processed.includes( row.car ) && !row.penalty && !row.comment )
      {
        // one based position
        pos++
        // add position to car
        row.pos = pos
        // add id to list to prevent duplicates
        processed.push( row.car )
      }
      else
      {
        // add dash to all other entries
        row.pos = '-'
      }
    }
    // return the result
    return sorted
  }

  // get data based on filters,
  // add position
  const getRows = ( rows ) => (
    positionAll 
    ? addPositionAll( filterData( rows ) )
    : addPositionOne( filterData( rows ) )
  )

  // rows for table and summary
  const all_rows = getRows( data )
  const mod_rows = modifyRows ? modifyRows( all_rows ) : all_rows 

  // add data to before, between or after component
  const getComponent = ( component) => React.cloneElement( component, { 
    rows: all_rows,
    car: car
  } )

  // return the filtertable
  return (
    <React.Fragment>
      {  before ? getComponent( before ) : null /* put something before? */ }
      { 
        // show summary?
        summary 
        ? <Summary rows={ all_rows } car={ car ? false : true } cones={ true } time={ true } laps={ true }/> 
        : null 
      }      
      {  between ? getComponent( between ) : null /* put something between? */ }
      <SortTable data={ mod_rows } modifyColumns={ modifyColumns } column_options={ column_options } context={ context } {...rest}/>
      {  after ? getComponent( after ) : null /* put something after? */ }
    </React.Fragment>
  )
}

export default FilterTable