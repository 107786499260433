import React from 'react'

const Icon = props =>
{
  const {
    icon = '',
    className = ''
  } = props

  //const Asset = icons.hasOwnProperty( icon ) ? icons[ icon ] : null
  
  return (
    <i className={ `icon icon-${icon} ${className}` }></i>
  )
}

export default Icon