import React from 'react'
import { useSelector } from 'react-redux'
import { getDisplayOptions } from '../redux/options/selectors' 
import { Divider, Panel } from 'rsuite'
import Icon from './Icon'

const Summary = ( { rows, ...show } ) => { 

  // get display options
  const display = useSelector( getDisplayOptions )

  // stop righ away if uses doesn't want to show the summary
  if ( !display.summary ) return null

  // get unique cars
  const cars = [ ...new Set( rows.map( d => d[ 'car' ] ) ) ].length

  // calculate number of cones
  const cones = rows.reduce( ( total, row ) => {
    // take missing / empty penalty into account
    if ( row.hasOwnProperty( 'penalty' ) && row.penalty > 0 )
    {
      return total + row.penalty
    }
    return total
  }, 0 ) / 5

  // get fastest time
  const fastest = rows.reduce( ( total, row ) => {
    if ( !total || row.laptime < total ) 
    {
      if ( !row.penalty && !row.comment )
      {
        return row.laptime
      }
    }
    return total
  }, '' )

  /**
   * Get children based on show prop
   * @returns 
   */
  const getChildren = () => 
  {
    const children = []
    if ( show.car ) 
    {
      children.push( <span key="cars" className="cars"><Icon icon="car"/> <b>{ cars }</b></span>  )
    }

    if ( show.laps ) 
    {
      children.push( <span key="laps" className="laps"><Icon icon="flag"/> <b>{ rows.length }</b></span> )
    }

    if ( show.cones ) 
    {
      children.push( <span key="cones" className="cones"><Icon icon="cone"/> <b>{ cones }</b> </span> )
    }

    if ( show.time ) 
    {
      children.push( <span key="time" className="fastest"><Icon icon="stopwatch"/> <b>{ fastest ? fastest : '00:00.000'}</b></span> )
    }

    // append divider if needed
    return children.map( ( child, index ) => (
        <React.Fragment>
          { child }
          { index !== children.length - 1 ? <Divider key={ `div-${index}` } vertical/>  : null }
        </React.Fragment>
    ) )
  }

  return (
    <Panel bordered className="summary">{ getChildren() }</Panel>
  )
}

export default Summary