const groupTypes = {
  FILTERS: 'filters',
  HOMEPAGE: 'homepage',
  GROUPPAGE: 'grouppage',
  CARPAGE: 'carpage',
  TABLE: 'table',
  FAST: 'fast',
  DISPLAY: 'display',
  NOTIFY: 'notify'
}

export default groupTypes