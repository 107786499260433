import React from 'react'
import Chart from 'react-google-charts'
import { useSelector } from 'react-redux'
import { getDisplayOptions, getYou } from '../redux/options/selectors' 
import { ms2laptime, laptime2ms } from '../tools/Time'

// non numerical default time, will be sorted to the back if the list
const default_time = 'xxx:xx.xxx'
const html_tooltip = true

/**
 * Generate an HTML tooltip for a row
 * @param {*} row 
 * @returns 
 */
const getTooltip = row => {
  if ( !row.data ) return `<div class="tooltip empty">${row.car}</div>`
  // this is unsafe HTML!
  return `<div class="tooltip">${row.car}&nbsp;#${row.position}&nbsp;@${row.data.time}<br/><b>${row.data.laptime}</b></div>`
}

/**
 * Render the position chart
 * @param {*} param0 
 * @returns 
 */
const ScatterChart = ( { rows = [], max_rows = 999, shorten = false, height = '100px' } ) =>
{
  // get display options
  const display = useSelector( getDisplayOptions )
  const you = useSelector( getYou )
  const { day_graph = true } = display

  // stop righ away if user doesn't want to show the position graph
  if ( !day_graph ) return null

  // stop here if there's no data
  if ( !rows || rows.length === 1 ) return null

  // sort by time so oldest item is first
  rows.sort( (a , b) => a.time > b.time ? 1 : -1 )

  // maybe limit number of rows to show
  const sliced = shorten && max_rows && max_rows < rows.length ? rows.slice( -1 * Math.min( max_rows + 1, rows.length ) ) : rows

  if ( !sliced || sliced.length <= 1 ) return null

  // range
  let min_laptime = 99999999999,
      max_laptime = 0,
      total_laptime = 0,
      min_hour = 999999999999,
      max_hour = 0      

  // trend range; go this many steps before and after the current step and average the result
  // const trend_range = Math.min( 20, Math.min( 20, Math.round( sliced.length / 5 ) ) )
  const trend_range = Math.round( sliced.length / 10 ),
        sliced_max = sliced.length - 1
  
  // get data format
  let data = sliced.map( ( row, index ) => 
  {          // get ms values
    const laptime = laptime2ms( row.laptime ),
          // get trend range
          trend_start_raw = index - trend_range,
          trend_start = Math.max( 0, trend_start_raw),
          // when at the start, add the first value numerous time to bend the graph to the start point
          trend_start_offset = Math.abs( trend_start_raw - trend_start ),
          trend_start_accent = trend_start_offset * laptime2ms( sliced[ trend_start ].laptime ),
          trend_end_raw = index + trend_range, 
          trend_end = Math.min( sliced_max, trend_end_raw ), 
          // when at the end, add the last value numerous time to bend the graph to the end point
          trend_end_offset = Math.abs( trend_end_raw - trend_end ),
          trend_end_accent = trend_end_offset * laptime2ms( sliced[ trend_end ].laptime ),
          // get trend average
          trend_slice = sliced.slice( trend_start, trend_end ),
          trend_total = trend_slice.reduce( ( total, current ) => ( total + laptime2ms( current.laptime ) ), 0 ),
          trend_avg = parseInt( ( trend_total + trend_start_accent + trend_end_accent ) / ( trend_end - trend_start + trend_start_offset + trend_end_offset ) )  

    // get min / max values
    total_laptime += laptime
    min_laptime = Math.min( laptime, min_laptime )
    max_laptime = Math.max( laptime, max_laptime )
    min_hour = Math.min( parseInt( row.time ), min_hour )
    max_hour = Math.max( parseInt( row.time ), max_hour )

    // dot color
    const clr_blue = '#3366cc',
          is_you = row.car === parseInt( you ),
          is_invalid = row.penalty > 0 || row.comment !== ''

    let style = `color: ${clr_blue}; fill_color: ${clr_blue}; stroke-color: ${clr_blue};`;
    if ( is_you ) 
    {      
      style = `color: red; fill_color: red; stroke-color: red;`;
      if ( is_invalid )
      {
        style = `color: orange; fill_color: orange; stroke-color: red;`;
      }
    }
    else if ( is_invalid )
    {
      style = `color: orange; fill_color: orange; stroke-color: orange;`;
    }

    // return the data row
    return [ 
      // x / h-axis = time of recording
      {
        v: row.time,
        f: row.time
      },
      // y / v-axis = laptime
      {
        v: laptime,
        f: row.laptime
      },
      // style column
      style,
      // y / v-axis = average laptime / trend
      {
        v: trend_avg,
        f: ms2laptime( trend_avg )
      }
    ] 
  } )
  // add header row
  data.unshift( [ 
    // x colummn
    'Lap',    
    // y column 1
    'Laptime', 
    // styles for y column 1
    { role: 'style' }, 
    // y column 2
    'Average' 
  ] ) 

  const avg_laptime = parseInt( total_laptime / rows.length ),
        med_laptime = parseInt( ( min_laptime + max_laptime ) / 2 ),
        hour_ticks = []

  let h_axis_min = '',
      h_axis_max = ''

  // add hour ticks
  for ( let i=min_hour; i<=max_hour+1; i++ )
  {
    const h = String( '0' + i ).substr( -2 ),
          s = `${h}:00:00`
    hour_ticks.push( { v: s, f: `${h}:00` } )
    if ( i === min_hour ) h_axis_min = s
    if ( i === max_hour + 1 ) h_axis_max = s
  }

  // render the chart
  return (
    <div className="chart scatter-chart" id="scatter-chart">
      <Chart 
        width={ '100%' }
        height={ height }
        chartType="ComboChart"
        options={ { 
          legend: 'none',
          tooltip: {
            trigger: 'none'
          },
          series: {
            0: {
              pointSize: 4,
              type: 'scatter'
            },
            1: {
              pointSize: 0,
              lineWidth: 2,
              type: 'line',
              curveType: 'function',
            }
          },
          vAxis: { 
            ticks: [ 
              { v: max_laptime, f: ms2laptime( max_laptime ) },             
              { v: avg_laptime, f: ms2laptime( avg_laptime ) },
              { v: min_laptime, f: ms2laptime( min_laptime ) }
            ],
            gridlines: {
              color: '#999'
            }
          },
          hAxis: {
            textPosition: 'none',
            ticks: hour_ticks,
            minValue: h_axis_min,
            maxValue: h_axis_max
          },
          chartArea: { 
            height: parseInt( height ),
            left: 60,
            top: 4, 
            right: 3,
            bottom: 18,
          },
        } }
        data={ data } 
      />
    </div>
  )
}

export default ScatterChart