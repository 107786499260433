import actionTypes from './types'

const INITIAL_STATE = {}

/**
 * Store an option
 * @param {*} state 
 * @param {*} action 
 * @returns 
 */
const addNotification = ( state, action ) =>
{
  // default props from payload
  const {
    type = 'all',
    title = '',
    body = '',
    children = '',
    icon = '',
    open = false,
    unique = false,
  } = action.payload

  // get list of existing types
  const types = type && state.hasOwnProperty( type ) && Array.isArray( state.type ) ? [ ...state.type] : []

  // add notification
  types.push( { title, body, icon, open, children, unique } )

  // return the modified state
  return {
    ...state,
    [ type ]: types
  }
}

/**
 * Clear all notifications of a type
 * @param {*} state 
 * @param {*} action 
 * @returns 
 */
const clearNotifications = ( state, action ) =>
{
  if ( typeof action.payload === 'string' && state.hasOwnProperty( action.payload ) )
  {
    // clone
    const all = { ...state }
    // remove the one
    delete( all[ action.payload ] )
    // return the new state
    return all
  }
  return state
}

/**
 * Remove a single notification
 * @param {*} state 
 * @param {*} action 
 * @returns 
 */
const clearNotification = ( state, action ) => state

/**
 * Reduce options
 * @param {*} state 
 * @param {*} action 
 * @returns 
 */
const notificationsReducer = ( state = INITIAL_STATE, action ) =>
{
  switch ( action.type )
  {        
    case actionTypes.ADD_NOTIFICATION:
      return addNotification( state, action )

    case actionTypes.CLEAR_NOTIFICATION:
      return clearNotification( state, action )

    case actionTypes.CLEAR_NOTIFICATIONS:
      return clearNotifications( state, action )

    default:
      return state;
  }
}

export default notificationsReducer