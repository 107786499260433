import React from 'react'
import { Dropdown } from 'rsuite'
import Icon from './Icon'

// Filter dropdown for sortable table
const FilterDropdown = ( props ) => {

  const {
    className = '',
    title = '',
    selectAll = 'All',
    icon = '',
    onSelect = null,
    selected = null,
    children = [],
    showAll = true,
    addActive = true
  } = props

  return (
    <Dropdown 
      className={ `filter ${className}`}
      appearance="default" 
      title={ [title, <Icon icon="arrow-down" className="carret"/> ] }
      icon={ icon ? <Icon icon={ icon } className={ selected !== null && selected !== '' && addActive ? 'active' : '' }/> : null }
      onSelect={ onSelect }
      activeKey={ selected ? selected : '' }
      noCaret={ true }
    >
    { [
      showAll ? <Dropdown.Item eventKey="" key={ `key-000` }>{ selectAll }</Dropdown.Item> : null,
        ...children 
    ] }        
    </Dropdown>
  )
}

export default FilterDropdown