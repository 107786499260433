import actionTypes from './types'
/**
 * Update an option
 * @param {*} step 
 */
export const setData = ( data = [] ) => ( {
  type: actionTypes.SET_DATA,    
  payload: data
} )


