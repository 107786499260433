import actionTypes from './types'
import groupTypes from './groups'

/**
 * Update an option
 * @param {*} step 
 */
export const setOption = ( key = '', value = null, group = null ) => ( {
  type: actionTypes.SET_OPTION,    
  payload: { key, value, group }
} )

/**
 * Update a filter option
 * @param {*} step 
 */
 export const setFilterOption = ( key = '', value = null ) => ( {
  type: actionTypes.SET_OPTION,    
  payload: { key, value, group: groupTypes.FILTERS }
} )

/**
 * Update a filter option
 * @param {*} step 
 */
 export const resetFilters = () => ( {
  type: actionTypes.SET_OPTION,    
  payload: { key: groupTypes.FILTERS, value: {} }
} )

/**
 * Update a homepage option
 * @param {*} step 
 */
 export const setHomeOption = ( key = '', value = null ) => ( {
  type: actionTypes.SET_OPTION,    
  payload: { key, value, group: groupTypes.HOMEPAGE }
} )

/**
 * Update a grouppage option
 * @param {*} step 
 */
 export const setGroupOption = ( key = '', value = null ) => ( {
  type: actionTypes.SET_OPTION,    
  payload: { key, value, group: groupTypes.GROUPPAGE }
} )

/**
 * Update a carpage option
 * @param {*} step 
 */
 export const setCarOption = ( key = '', value = null ) => ( {
  type: actionTypes.SET_OPTION,    
  payload: { key, value, group: groupTypes.CARPAGE }
} )

/**
 * Update a table option
 * @param {*} step 
 */
 export const setTableOption = ( key = '', value = null ) => ( {
  type: actionTypes.SET_OPTION,    
  payload: { key, value, group: groupTypes.TABLE }
} )


