import React, { useEffect } from 'react'

// components
import CarDropdown from '../components/CarDropdown'
import FilterTable from '../components/FilterTable'
import { ButtonToolbar, Container, Header, Content } from 'rsuite';
import TimeChart from '../components/TimeChart';

// redux
import { useSelector, useDispatch } from 'react-redux'
import { getContextOptions, getOptions, getYou } from '../redux/options/selectors'  
import { getAllCars } from '../redux/data/selectors'
import { setOption } from '../redux/options/actions'

import { useMatomo } from '@datapunt/matomo-tracker-react'
import { ms2diff, laptime2ms } from '../tools/Time.js'
import { useParams, useHistory } from 'react-router'

// Group page
const CarPage = ( { context = 'carpage' }) => {

  const { trackPageView } = useMatomo()

  // redux
  const dispatch = useDispatch()
  const { car = '', group = '' } = getContextOptions( useSelector( getOptions ), context )
  const cars = useSelector( getAllCars )
  const you = useSelector( getYou )
  const { car: param } = useParams()
  const history = useHistory()

  // set car on startup if needed
  useEffect( () => {

    trackPageView()

    // reset group if needed
    if ( group )
    {      
      dispatch( setOption( 'group', '', context ) )
    }
    
    // change car by url param
    if ( param !== car && cars.includes( parseInt( param ) ) )
    {
      dispatch( setOption( 'car', param, context) )
    }

    // use selected group by default
    if ( !car )
    {      
      if ( you !== '' && cars.includes( parseInt( you ) )  )
      {
        // use 'your' car as third option
        dispatch( setOption( 'car', you, context) )
      }
      else
      {
        // otherwise use first car
        dispatch( setOption( 'group', cars[ 0 ], context ) )
      }
    }
  }, [] )

  // no url param but a you? go there
  if ( !param && you && cars.includes( parseInt( you ) ) )
  {
    history.push( '/car/' + you )
    return null
  }

  // set column options
  const column_options = {
    cat: false,
    id: false,
    lap: false,
    diff: true,
    pos: false
  } 

  // modify row data; add laptime improvement
  const modifyRows = ( rows ) => 
  {
    // check for a valid object
    if ( rows && rows.length )
    {
      // sort on creation date
      rows.sort( ( a, b ) => a.time > b.time )
      // previous fastest time(stamp) in ms
      let fast_time = laptime2ms( rows[ 0 ].laptime )
      // calculate laptime improvement for each row
      rows.forEach( row => {
        // convert to miliseconds
        const time = laptime2ms( row.laptime )
        // get time difference
        row.diff = ms2diff( time - fast_time )
        row.faster = false
        // store faster time
        if ( time < fast_time ) 
        {
          row.faster = true
          fast_time = time
        }
      } )
    }
    // always return the rows
    return rows
  }

  // change url when dropdown changes
  const onChange = value => history.push( '/car/' + value )

  // add faster row class
  const rowClassName = ( row, className ) => className + row.faster ? ' faster' : ''

  // render the car page
  return (
    <Container className={ context }>
      <Header>
        <ButtonToolbar className="filters">
          <CarDropdown showAll={ false } context={ context } onChange={ onChange }/>     
        </ButtonToolbar>
      </Header>
      <Content>      
        <FilterTable 
          context={ context } 
          column_options={ column_options } 
          modifyRows={ modifyRows }
          rowClassName={ rowClassName }
          between={ <TimeChart/> } 
          clickRow={ false }
        />
      </Content>
    </Container>
  )
}
export default CarPage