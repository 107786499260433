import actionTypes from './types'

const INITIAL_STATE = []

// lap counter per car
const laps = {}

/**
 * Store an option
 * @param {*} state 
 * @param {*} action 
 * @returns 
 */
const setData = ( state, action ) =>
{
  if ( action.payload )
  {
    let last_car = '',
        last_time = '',
        // new rows to be added
        rows = [],
        // get last 10 rows to check for duplicates
        last = state.sort( ( a, b ) => a.time < b.time ? 1 : -1 ).slice( 0, 10 )

    // sort on timestamp to check for duplicates
    action.payload.sort( ( a, b ) => a.time < b.time ? 1 : -1 )

    // add missing data
    for( const row of action.payload )
    {
      // position is to be determined in relation the group later
      row.pos = '-'

      // time difference with the previous fastest time
      row.diff = '-'

      // make sure penalty exists and is a number
      if ( !row.hasOwnProperty( 'penalty' ) || !row.penalty )
      {
        row.penalty = 0
      }
      else
      {
        row.penalty = parseInt( row.penalty )
      }

      // make sure comment exists 
      if ( !row.hasOwnProperty( 'comment' ) || !row.comment )
      {
        row.comment = ''
      }
      else
      {
        row.comment = row.comment.trim()
      }  

      // remove duplicates in the payload
      if ( last_car !== parseInt( row.car ) && last_time !== String( row.laptime ) )
      {
        // store last car and time to check for duplicates
        last_car = parseInt( row.car )
        last_time = String( row.laptime )
        rows.push( row )          
      }
    }

    // add lap number per user entry
    rows.reverse()
    rows.forEach( row => 
    {      
      if ( !laps.hasOwnProperty( row.car ) ) laps[ row.car ] = 0
      laps[ row.car ]++
      row.lap = laps[ row.car ]
    })

    // merge
    return [
      ...state, 
      ...rows
    ]
  }

  return state
}

/**
 * Reduce data
 * @param {*} state 
 * @param {*} action 
 * @returns 
 */
const dataReducer = ( state = INITIAL_STATE, action ) =>
{
  switch ( action.type )
  {        
    case actionTypes.SET_DATA:
      return setData( state, action )

    default:
      return state;
  }
}

export default dataReducer