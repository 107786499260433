// list of subscriptions / events
const subscriptions = {}

// subscriptions counter, used as identifier for individual subscription
let count = 0

/**
 * Add an event and return the ID
 * @param {*} event 
 */
export function subscribe() 
{
  // list of new subscriptions to be returned
  const subscribed = []

  // go over all passed arguments
  for ( const m in arguments )
  {
    // create unique string ID
    const id = 's_' + count++

    // create a new sunscrinton object
    const subscription = {
      // ID of the subscription
      id: id,
      // method to be called
      method: arguments[ m ],
      // last action
      action: {},
      // store get state method
      getState: () => { console.error( 'No store set' ) },
      // store dispatch method
      dispatch: () => { console.error( 'No store set' ) },
      // unsubscribe method, unsubscribes the current event by defaukt
      unsubscribe() { unsubscribe( arguments.length > 0 ? arguments : subscription ) },
      // subscribe method to add new subscriptions
      subscribe: subscribe    
    }

    // add it to the main list
    subscriptions[ id ] = subscription

    // add it to the output list
    subscribed.push( subscription )
  }
  
  // return the subscriptions
  return subscribed
}

/**
 * Remove one or many subscriptions
 * @param {*} id 
 */
export function unsubscribe()
{
  // go over all passed asrguments
  for ( const i in arguments )
  {
    delete subscriptions[ arguments[ i ].id ] 
  }
}

/**
 * Handle all subscriptions in the list
 * @param {*} store 
 */
const handleSubscriptions = ( store, action ) =>
{
  // go over all subscriptions
  for ( const id in subscriptions )
  {
    // get the subscription object
    // and merge in the current store and action
    const subscription = {
      ...subscriptions[ id ],
      ...{
        action: action,
        getState: store.getState,
        dispatch: store.dispatch
      }
    }
    
    // run the event and pass a subscription object    
    subscription.method( subscription ) 
  }
}

/**
 * subscripion middleware
 * @param {*} store 
 */
export const subscriptionMiddleware = store => next => action => 
{
  let result = next( action )
  handleSubscriptions( store, action )
  return result
}