// redux
import { applyMiddleware, createStore } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

// middlewares
//import thunk from 'redux-thunk'
// import logger from 'redux-logger'
import { subscriptionMiddleware } from './middleware/subscriptions'

// root reducer
import rootReducer from './root-reducer'

// perist config
const persistConfig = {
  key: 'WimedoApp', // storage name
  storage,
  blacklist: [ 'data', 'notifications' ] // don't store data; get it from server instead
}

let middlewares = [
  // thunk,  
  subscriptionMiddleware
]

// middlewares.push( logger )

// create store
export const store = createStore(
  // add reducer
  persistReducer( persistConfig, rootReducer ),
  // inital state
  {},
  // add middleware
  applyMiddleware( ...middlewares )
)

export const persistor = persistStore( store )