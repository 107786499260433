import React from 'react'
import Chart from 'react-google-charts'
import { useSelector } from 'react-redux'
import { getDisplayOptions, getYou } from '../redux/options/selectors' 
import { getUnique } from '../tools/Array'

// non numerical default time, will be sorted to the back if the list
const default_time = 'xxx:xx.xxx'
const html_tooltip = true
let sorted_cars = []

/**
 * Generate an HTML tooltip for a row
 * @param {*} row 
 * @returns 
 */
const getTooltip = row => {
  if ( !row.data ) return `<div class="tooltip empty">${row.car}</div>`
  // this is unsafe HTML!
  return `<div class="tooltip">${row.car}&nbsp;#${row.position}&nbsp;@${row.data.time}<br/><b>${row.data.laptime}</b></div>`
}

/**
 * Remove all rows without position change
 * @param {*} rows 
 */
const shortenData = rows => 
{
  // remember last entry
  let last_hash = ''
  // filter out unique values
  return rows.filter( row => {
    // make sort of a hash string to compare uniqueness
    const row_hash = row.slice( 1 ).map( r => r.pos_hash ).join( '-' )
    // only add if this entry isn't the same as the previous one
    if ( row_hash !== last_hash )
    {
      last_hash = row_hash
      return true
    }
    return false
  }) 
}

/**
 * // update laptime of a specific car / row
 * @param {*} row 
 * @returns 
 */
const updateLaptime = ( row, last_time, last_pos ) =>
{
  // go over all previously set times
  last_time.forEach( ( last, index ) => 
  {
    // find the car
    if ( String( last.car ) === String( row.car ) )
    {
      // maybe update 
      if ( row.laptime < last.laptime || last.laptime === default_time )
      {        
        // clone the entire row
        last_time[ index ] = { ...row }
      }
      // stop here
      return
    }
  })

  // sort on laptime so the fastest is first
  last_time.sort( ( a, b ) => a.laptime > b.laptime ? 1 : -1 )

  // add last positions based on laptime
  last_time.forEach( ( last, index ) =>
  {
    if ( last.laptime !== default_time )
    {
      // add position and merge in row data for tooltip
      last_pos[ last.car ] = { 
        // position of the car
        position: index + 1,
        // car number
        car: last.car,
        // hash based on position
        pos_hash: `${last.car}-${index+1}`,
        // hash based on position and laptime
        time_hash: `${last.car}-${index+1}-${last.laptime}`,
        // raw data
        data: last
      }
    }
  })

  // just return the values, maintain (car) order hopefully
  return Object.values( last_pos )
}

/**
 * Get graph header
 * @param {*} param0 
 * @returns 
 */
const getHeader = cars => 
{
  // add header row, position + car numbers as strings
  let header = [ 'Lap' ]
  cars.forEach( c => {
    // add car number row
    header.push( String( c ) )
    
    if ( html_tooltip )
    {
      // add tooltip row
      header.push( {
        type: 'string', 
        role: 'tooltip',
        p: { html: true }
      } )
    }
  })
  return header
}

/**
 * get car numbers sorted on position from the last row
 * @param {*} rows 
 */
const getSortedCarNums = ( rows ) => 
{
  // get a copy of the last row
  const last_row = [ ...rows[ rows.length - 1 ] ]
  // remove the first item, this is the lapnumber or x-axis index
  last_row.shift()
  // sort on position
  const last_sorted = last_row.sort( ( a, b ) => a.position < b.position )
  // extract the car numbers
  return last_sorted.map( l => l.car )
}

/** 
 * Get data for the graph
 * @param {*} cars 
 * @param {*} rows 
 */
const getGraphData = ( cars, rows ) =>
{
  // we need this later
  sorted_cars = getSortedCarNums( rows )

  // put it together, ALWAYS add the header column
  let data = [ getHeader( cars ) ]
  rows.forEach( ( row, index ) => 
  {
    // prep data row; lap, ( position, toolip ) * cars
    let d = []

    // go over each element in the sliced row
    row.forEach( r => {
      if ( Number.isInteger( r ) )
      {
        // add index, not lapnumber as lapnumber makes no sense to the user
        // this is the first or header column
        d.push( index )
      }
      else
      {
        // add negative position, we revert this in the v-axis ticks
        d.push( { v: -r.position, f: `${-r.position}` } )

        if ( html_tooltip )
        {
          // add tooltip
          d.push( getTooltip( r ) )       
        }
      }
    })
    // add data row
    data.push( d )
  })

  return data
}

/**
 * Fix the legend after render
 */
const fixLegend = ( e ) => 
{
  // get chart and svg
  const el = document.getElementById( 'position-chart' ),
        svg = el ? el.querySelector( 'svg' ) : null,
        g1 = el ? el.querySelectorAll( 'svg > g' ) : null,
        nums = g1 ? g1[ 0 ] : null,
        graph = g1 ? g1[ 1 ] : null,
        g2 = graph ? graph.querySelectorAll( ':scope > g' ) : null,
        pos = g2 ? g2[ g2.length - 1 ] : null

  if ( nums )
  {
    nums.style.display = 'none'
  }

  if ( pos && svg )
  {
    // clone position element and find text nodes
    const newpos = pos.cloneNode( true ),
          texts = newpos.querySelectorAll( 'text' )

    // move text nodes to the right of the grapth and add car numbers in the correct order
    texts.forEach( ( text, index ) => {
      text.setAttribute( 'x', svg.clientWidth )
      text.textContent = sorted_cars[ index ]
    } )

    svg.appendChild( newpos )
  }

}

/**
 * Render the position chart
 * @param {*} param0 
 * @returns 
 */
const PositionChart = ( { rows = [], max_rows = 10, car = '', shorten = true } ) =>
{
  // get display options
  const display = useSelector( getDisplayOptions )
  const you = useSelector( getYou )

  // stop righ away if user doesn't want to show the position graph
  if ( !display.position_graph ) return null

  // stop here if there's no data
  if ( !rows || rows.length === 1 ) return null

  // remove laps with penalties or remarks
  const valid = rows.filter( row => row.penalty === 0 && row.comment === '' )

  // stop here if there's no valid data
  if ( !valid || valid.length === 1 ) return null

  // sort by time so oldest item is first
  valid.sort( (a , b) => a.time > b.time ? 1 : -1 )

  // get a list of unique cars
  const cars = getUnique( valid, 'car' )

  // stop here if there's not enough cars
  if ( !cars || cars.length <= 1 ) return null

  // add last known laptime for each car (make if really big), use an array so we can sort it
  const last_time = cars.map( c => ( { car: c, laptime: default_time } ) )

  // last position is an object, doesn't need nor want sorting
  const last_pos = {}
  cars.forEach( c => last_pos[ c ] = { 
    position: cars.length, 
    car: c,
    pos_hash: `${c}-0`,
    time_hash: `${c}-0-0:00.000`,
    data: null 
  } )

  // each lap, add laptime for the car that did the lap & reacalculate each cars position
  const positions = valid.map( ( row, index ) => [
    // add lapnumber first
    ...[ index + 1 ],
    // add cars and positions next
    ...updateLaptime( row, last_time, last_pos )
  ] )

  // shorten array by only showing laptimes that changed the order
  let uniqued = shorten ? shortenData( positions ) : positions

  // maybe limit number of rows to show
  const sliced = max_rows ? uniqued.slice( -1 * Math.min( max_rows + 1, uniqued.length ) ) : uniqued

  // get graph data
  const data = getGraphData( cars, sliced )

        // point size ( and top / bottom )
  const pointSize = 2,
        lineWidth = 1,
        // room to the left and right
        leftSide = 30,
        rightSide = 30

  // height based on number of cars
  const height = Math.max( 60, cars.length * 23 )

  // build colors
  const series = {}

  // highligh current / your car
  const carIndex = cars.findIndex( c => String( c ) === String( car ) || String( c ) === String( you ) )
  if ( carIndex >= 0 )
  {
    series[ carIndex ] = { 
      color: 'red',
      pointSize: pointSize * 2,
      lineWidth: lineWidth * 2
    }
  }

  // map v-axis tick
  const vAxisTicks = cars => {
     return cars.map( ( v, i ) => {
      const f = i + 1
      return { v: -f, f: `${f}`}
    } )
  }

  // render the chart
  return (
    <div className="linechart chart position-chart" id="position-chart">
      <Chart 
        width={ '100%' }
        height={ height + 'px' }
        chartType="LineChart" 
        chartEvents={[
          {
            eventName: 'ready',
            callback: fixLegend
          }
        ]}
        options={ { 
          height: height,
          pointSize: pointSize,
          lineWidth: lineWidth,
          series: series,
          vAxis: { 
            ticks: vAxisTicks( cars ), 
            title: '',
            gridlines: {
              color: '#eee'
            },
          },
          hAxis: { 
            ticks: [], 
            title: ''
          },
          chartArea: { 
            height: height,
            left: leftSide,
            top: 10, 
            right: rightSide,
            bottom: 10,
          },
          tooltip: { 
            isHtml: true, 
            trigger: html_tooltip ? 'visible' : 'none'
          }
        } }
        data={ data } 
      />
    </div>
  )
}

export default PositionChart